<template>
  <div class="d-flex flex-column py-6">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <h1 class="text-h4 secondary--text font-weight-bold mb-3">
          <span>Seja bem vindo</span>
        </h1>

        <div class="text-body-1 font-weight-bold mb-8">
          <span>Informe suas credenciais para entrar.</span>
        </div>

        <v-form v-model="valid" ref="form">
          <div @keypress.enter.prevent="submit()" class="mb-6">
            <custom-input
              v-model="form.email"
              label="Email"
              placeholder="email@examplo.com"
              outlined
            />

            <custom-input
              v-model="form.password"
              label="Senha"
              placeholder="Digite sua senha"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="showPassword ? 'text' : 'password'"
              outlined
              @click:append="showPassword = !showPassword"
            />

            <div class="d-flex justify-center">
              <router-link
                to="/auth/forgot-password"
                class="text-decoration-none"
              >
                <span class="text-body-1 font-weight-bold primary--text">
                  Esqueceu sua senha?
                </span>
              </router-link>
            </div>
          </div>

          <div class="text-center mb-4">
            Ao acessar você aceita
            <a class="mx-1" @click.stop.prevent="openTerms()">
              termos de aceite, condições de uso e contratual
            </a>
            da plataforma.
          </div>

          <v-btn
            class="mb-6 rounded-xl secondary--text"
            color="primary2"
            block
            large
            @click="submit()"
          >
            Entrar agora
          </v-btn>

          <div class="d-flex justify-center text-center">
            <router-link
              to="/auth/sign-up"
              class="text-decoration-none text-body-1"
            >
              <span>
                <span class="overtext--text font-weight-bold">
                  Não tem uma conta?
                </span>

                <span class="font-weight-bold secondary--text">
                  Cadastre-se gratuitamente
                </span>
              </span>
            </router-link>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setAuthorized"]),

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "loginUser",
          email: this.form.email,
          pass: this.form.password,
        };

        await request(payload).then((res) => {
          this.setToken(res.authToken);
          this.getUser();
        });
      } catch (err) {
        this.displayAlert(err.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getUser() {
      try {
        const payload = {
          method: "getUserData",
        };

        await request(payload).then((res) => {
          this.setUser(res.userData);

          if (this.handleUserData(res.userData)) {
            this.setAuthorized(true);
            this.$router.push({ path: "/home" });
          } else {
            this.setAuthorized(false);
            this.$router.push({ path: "/account" });
          }
        });
      } catch (err) {
        // err
      }
    },

    handleUserData(data) {
      return (
        Object.keys(data.dadosPessoais).length > 0 &&
        Object.keys(data.dadosResidencial).length > 0
      );
    },

    openTerms() {
      window.open("https://earthguard.com.br/termos-de-uso/", "_blank").focus();
    },

    displayAlert,
  },
};
</script>

<style></style>
